import { getAddress } from '@ethersproject/address'

export const DEXES = {
  'uni-v3': 'https://gateway-arbitrum.network.thegraph.com/api/f25f035ebb6569a88522ed6ecb93a692/subgraphs/id/5zvR82QoaXYFyDEKLZ9t6v9adgnptxYpKpSbxtgVENFV',
  '9mm-v3': 'https://graph.9mm.pro/subgraphs/name/pulsechain/9mm-v3',
  '9inch-v3': 'https://node.9inch.io/subgraphs/name/nineinch/exchange-v3-mainnet'
}

export const POSITION_URLS = {
  'uni-v3': 'https://app.uniswap.org/#/pool/{poolId}?chain=mainnet',
  '9mm-v3': 'https://dex.9mm.pro/liquidity/{poolId}?chain=pulsechain',
  '9inch-v3': 'https://v3.9inch.io/liquidity/{poolId}?chain=pulse'
}

export const EXPLORER_URLS = {
  'uni-v3': 'https://etherscan.com/address/{addy}',
  '9mm-v3': 'https://pulsescan.korkey.tech/#/address/{addy}',
  '9inch-v3': 'https://pulsescan.korkey.tech/#/address/{addy}'
}

export const ICON_URLS = {
  'uni-v3': 'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/{addy}/logo.png',
  '9mm-v3': 'https://tokens.9mm.pro/pulse/{addyVer}.png',
  '9inch-v3': 'https://app.9inch.io/images/369/symbols/{sym}.png'
}

export const STABLECOINS = [
  getAddress('0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'), // USDC_TOKEN
  getAddress('0xdac17f958d2ee523a2206206994597c13d831ec7'), // USDT_TOKEN
  getAddress('0xa47c8bf37f92abed4a126bda807a7b7498661acd'), // UST_TOKEN
  getAddress('0x6b175474e89094c44da98b954eedeac495271d0f'), // DAI_TOKEN
]
